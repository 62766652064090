<template>
  <div class="tryout-container">
    <!-- Kolom Kiri -->
    <div class="left-column">
      <h3>Informasi Peserta</h3>
      
      <p><strong>Email:</strong> {{ user.email }}</p>
      <h3>Informasi Kategori</h3>
      <p><strong>Kategori:</strong> {{ category.name }}</p>
      <p><strong>Jumlah Soal:</strong> {{ questions.length }}</p>
    </div>

    <!-- Kolom Tengah -->
    <div class="middle-column">
      <div class="instructions">
        <h3>Petunjuk Pengerjaan</h3>
        <p>Jawablah setiap soal dengan memilih salah satu jawaban yang tersedia. Klik "Submit Tryout" setelah selesai.</p>
        
      </div>
      <div class="question-container" v-if="currentQuestion">
        <h4>Soal {{ currentIndex + 1 }} dari {{ questions.length }}</h4>
        <p>{{ currentQuestion.question_text }}</p>
        <div v-for="(option, idx) in currentQuestion.options" :key="idx">
          <label>
            <input
              type="radio"
              :name="'question-' + currentQuestion.id"
              :value="option.key"
              v-model="userAnswers[currentQuestion.id]"
            />
            {{ option.key }}. {{ option.value }}
          </label>
        </div>
        <div class="navigation-buttons">
          <button @click="prevQuestion" :disabled="currentIndex === 0">Kembali</button>
          <button @click="nextQuestion" :disabled="currentIndex === questions.length - 1">Lanjut</button>
        </div>
      </div>
      <div v-else>
        <p>Loading...</p>
      </div>
      <button @click="submitTryout" class="submit-button">Submit Tryout</button>
    </div>

    <!-- Kolom Kanan -->
    <div class="right-column">
      <button class="stop-button" @click="stopTryout">Akhiri Tryout</button>
      <div class="question-icons">
        <div
          v-for="(question, index) in questions"
          :key="index"
          :class="['question-icon', { answered: userAnswers[question.id] }]"
          @click="goToQuestion(index)"
          title="Klik untuk menuju soal ini"
        >
          {{ index + 1 }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import api from "@/services/api";

export default {
  data() {
    return {
      user: {
        id: [], // Akan diambil dari backend
        name: "",
        email: localStorage.getItem("userEmail") || "",
      },
      category: {
        name: "Tryout Mandiri", // Default kategori
      },
      questions: [],
      userAnswers: {},
      currentIndex: 0,
      tryoutCompleted: false,
    };
  },
  computed: {
    currentQuestion() {
      return this.questions[this.currentIndex];
    },
  },
  methods: {
    async fetchQuestions(categoryId) {
      try {
        const response = await api.get(`/categories/${categoryId}/questions/`);
        this.questions = response.data.map((question) => ({
          ...question,
          options: Object.entries(question.options).map(([key, value]) => ({
            key,
            value,
          })),
        }));
      } catch (error) {
        console.error("Error fetching questions:", error);
      }
    },

    async fetchUserDetails() {
      try {
        const response = await api.get("/auth/user-details", {
          params: { email: this.user.email },
        });
        this.user.id = response.data.id; // Simpan ID pengguna
        console.log("User ID fetched:", this.user.id); // Debugging
      } catch (error) {
        console.error("Error fetching user details:", error);
      }
    },

    nextQuestion() {
      if (this.currentIndex < this.questions.length - 1) {
        this.currentIndex++;
      }
    },
    prevQuestion() {
      if (this.currentIndex > 0) {
        this.currentIndex--;
      }
    },
    goToQuestion(index) {
      this.currentIndex = index;
    },
    stopTryout() {
      const confirmed = confirm("Apakah Anda yakin ingin mengakhiri tryout?");
      if (confirmed) {
        this.$router.push("/dashboard");
      }
    },

    async submitTryout() {
      const tryoutSessionId = `session-${Date.now()}-${Math.random().toString(36).substring(2, 15)}`;
      const userId = this.user.id; //localStorage.getItem("userId"); // Ambil userId dari localStorage
      const categoryId = this.$route.params.id;
      
      if (!tryoutSessionId) {
        alert("Gagal mengirim jawaban. Tryout session ID tidak ditemukan.");
        return;
      }
       // Periksa apakah userId tersedia
      if (!userId || userId === "undefined") {
        console.error("User ID tidak ditemukan di localStorage.");
        alert("Gagal mengirim jawaban. User ID tidak ditemukan.");
        return;
      }
      
      const unanswered = this.questions.filter((q) => !this.userAnswers[q.id]);
      if (unanswered.length > 0) {
        const confirmed = confirm(`Anda belum menjawab ${unanswered.length} soal. Lanjutkan submit?`);
        if (!confirmed) return;
      }

      
      const answers = Object.entries(this.userAnswers).map(([id, option]) => ({
        question_id: id,
        selected_option: option,
      }));

      localStorage.setItem("tryoutSessionId", tryoutSessionId); // Simpan tryout session ID
      console.log("Payload sebelum submit:", { userId, categoryId, answers, tryoutSessionId });
      
      
      try {
        const response = await api.post("/tryout-results/submit", {
          userId: this.user.id,
          categoryId: this.$route.params.id,
          answers,
          tryoutSessionId: localStorage.getItem("tryoutSessionId"),
        });

        console.log("Submit success:", response.data);
        
        this.tryoutCompleted = true;
        this.$router.push(`/hasil-tryout/${this.$route.params.id}`);
      } catch (error) {
        console.error("Error submitting tryout:", error);
        alert("Gagal submit tryout. Silakan coba lagi.");
      }
    },
  },
  mounted() {
    const categoryId = this.$route.params.id;
    if (!categoryId) {
      console.error("Category ID not found in route.");
      this.$router.push("/dashboard");
      return;
    }

    // Buat session ID jika belum ada
    if (!localStorage.getItem("tryoutSessionId")) {
        const tryoutSessionId = `session-${Date.now()}-${Math.random().toString(36).substring(2, 15)}`;
        localStorage.setItem("tryoutSessionId", tryoutSessionId);
      }

    const savedAnswers = JSON.parse(localStorage.getItem(`answers-${categoryId}`));
    if (savedAnswers) {
      this.userAnswers = savedAnswers;
    }

    this.fetchUserDetails()
    .then(() => {
      console.log("User details fetched during mounted.");
    })
    .catch((error) => {
      console.error("Error fetching user details during mounted:", error);
    });
    this.fetchQuestions(this.$route.params.id);
  },
  
  beforeRouteLeave(to, from, next) {
    if (!this.tryoutCompleted) {
      const confirmLeave = confirm("Anda yakin ingin submit tryout?");
      if (!confirmLeave) {
        next(false);
      } else {
        next();
      }
    } else {
      next();
    }
  },
};
</script>


<style scoped>
.tryout-container {
  display: grid;
  grid-template-columns: 1fr 2fr 1fr;
  gap: 20px;
  padding: 20px;
}
.left-column,
.middle-column,
.right-column {
  border: 1px solid #ccc;
  padding: 15px;
  border-radius: 8px;
}
.middle-column {
  background-color: #f9f9f9;
}
.instructions {
  margin-bottom: 20px;
  background-color: #e8f5e9;
  padding: 10px;
  border-radius: 5px;
}
.navigation-buttons button {
  margin: 5px;
  padding: 10px 20px;
  background-color: #4caf50;
  color: white;
  border: none;
  border-radius: 4px;
  font-size: 14px;
  cursor: pointer;
}
.navigation-buttons button:disabled {
  background-color: #cccccc;
  cursor: not-allowed;
}
.stop-button {
  display: block;
  width: 100%;
  background-color: #f44336;
  color: white;
  padding: 10px;
  border: none;
  border-radius: 4px;
  font-size: 16px;
  margin-bottom: 20px;
  cursor: pointer;
}
.question-icons {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}
.question-icon {
  width: 40px;
  height: 40px;
  border: 1px solid #ccc;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  background-color: #f5f5f5;
}
.question-icon.answered {
  background-color: #4caf50;
  color: white;
}
</style>

<template>
  <div class="result-container">
    <div class="header">
      <h1>Hasil Tryout</h1>
    </div>
    <div class="summary-section">
      <div class="summary-item">
        <span class="label">Kategori:</span>
        <span class="value">{{ category }}</span>
      </div>
      <div class="summary-item">
        <span class="label">Skor Total:</span>
        <span class="value score">{{ computedScore || 0 }} dari 100</span>
      </div>
    </div>
    <div class="results-section">
      <h2>Detail Jawaban</h2>
      <div v-if="isLoading">Memuat hasil tryout...</div>
        <div v-else>
          <!-- Konten tabel -->
        
      <table>
        <thead>
          <tr>
            <th>No.</th>
            <th>Pertanyaan</th>
            <th>Jawaban Anda</th>
            <th>Jawaban Benar</th>
            <th>Status</th>
          </tr>
        </thead>
        <tbody>
        <tr v-if="results.length === 0">
          <td colspan="5">Tidak ada hasil ditemukan.</td>
        </tr>
        <tr v-for="(result, index) in results" :key="index">
          <td>{{ index + 1 }}</td>
          <td>{{ result.question_text || "Pertanyaan tidak ditemukan" }}</td>
          <td>{{ result.selected_option || "-" }}</td>
          <td>{{ result.correct_option || "-" }}</td>
          <td>
            <span :class="{'correct': result.is_correct, 'incorrect': !result.is_correct}">
              {{ result.is_correct ? 'Benar' : 'Salah' }}
            </span>
          </td>
        </tr>
      </tbody>
      </table>
    </div>
    </div>
    
  </div>
</template>


<script>
import api from "@/services/api";

export default {
  data() {
    return {
      results: [],
      category: "Tryout Umum",
      isLoading: true, // State loading
      computedScore: [], // Default score
    };
  },
  methods: {
    async fetchTryoutResults(categoryId) {
      this.isLoading = true;
      try {
        const tryoutSessionId = localStorage.getItem("tryoutSessionId");
        const response = await api.get(`/tryout-results/${categoryId}`, {
          params: { tryoutSessionId },
          headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
        });

        if (response.status !== 200) {
          console.error("Unexpected response:", response);
          alert("Terjadi masalah saat memuat hasil tryout.");
          return;
        }


        const { score, results } = response.data;

        this.results = results || [];
        this.computedScore = score || 0;
      } catch (error) {
        console.error("Error fetching tryout results:", error);
        alert("Gagal memuat hasil tryout.");
      } finally {
        this.isLoading = false;
      }
    },
  },
  mounted() {
    const categoryId = this.$route.params.id;
    const tryoutSessionId = localStorage.getItem("tryoutSessionId");

    if (!categoryId || !tryoutSessionId) {
      alert("ID kategori atau sesi tryout tidak ditemukan.");
      this.$router.push("/dashboard");
      return;
    }
    console.log("Fetching tryout results for category:", categoryId);
    this.fetchTryoutResults(categoryId); // Memanggil data dari backend
  },
};
</script>

<style scoped>
.result-container {
  max-width: 800px;
  margin: 0 auto;
  font-family: 'Arial', sans-serif;
}

.header {
  text-align: center;
  margin-bottom: 20px;
}

.summary-section {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  background-color: #f9f9f9;
  padding: 10px 20px;
  border-radius: 8px;
  border: 1px solid #ddd;
}

.summary-item {
  display: flex;
  flex-direction: column;
  text-align: center;
}

.label {
  font-weight: bold;
  font-size: 16px;
}

.value {
  font-size: 18px;
  margin-top: 5px;
}

.value.score {
  color: #4caf50;
  font-size: 24px;
  font-weight: bold;
}

.results-section {
  margin-top: 20px;
}

table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 20px;
}

table th, table td {
  border: 1px solid #ddd;
  text-align: left;
  padding: 8px;
}

table th {
  background-color: #f2f2f2;
  font-size: 14px;
}

table td {
  font-size: 14px;
}

.correct {
  color: #4caf50;
  font-weight: bold;
}

.incorrect {
  color: #f44336;
  font-weight: bold;
}

.actions {
  text-align: center;
  margin-top: 20px;
}

.actions button {
  padding: 10px 20px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.actions button:hover {
  background-color: #0056b3;
}
</style>

import { createRouter, createWebHistory } from "vue-router";
import HomeView from "../views/HomeView.vue";
import LoginView from "../views/LoginView.vue";
import CategoryDetail from "../views/CategoryDetail.vue";
import RegisterView from "../views/RegisterView.vue";
import TryoutView from "../views/TryoutView.vue";
import HasilTryout from "../views/HasilTryout.vue";
import UserDashboard from "../views/UserDashboard.vue";

const routes = [
  { path: "/", component: HomeView },
  {
    path: "/login",
    name: "Login",
    component: LoginView,
  },
  { path: "/categories/:id", component: CategoryDetail },
  { path: "/register", component: RegisterView },
  { path: "/tryout/:id", component: TryoutView,meta: { requiresAuth: true } },
  { path: "/hasil-tryout/:id", component: HasilTryout, name: "HasilTryout", meta: { requiresAuth: true } },
  {
    path: "/dashboard",
    name: "Dashboard",
    component: UserDashboard,
    meta: { requiresAuth: true }, // Pastikan pengguna sudah login
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes, // daftar rute
});

// Middleware untuk memeriksa autentikasi
router.beforeEach((to, from, next) => {
  const token = localStorage.getItem("token");
  if (to.matched.some((record) => record.meta.requiresAuth) && !token) {
    next("/login");
  } else {
    next();
  }
});


export default router;

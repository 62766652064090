import axios from 'axios';

const api = axios.create({
    baseURL: 'https://api.testonline.id', // Ganti dengan URL backend Anda http://127.0.0.1:5000 server https://api.testonline.id
    headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    timeout: 10000,
});

// Tambahkan token ke setiap request jika tersedia
api.interceptors.request.use((config) => {
  const token = localStorage.getItem('token'); // Ambil token dari localStorage
  if (token) {
    config.headers.Authorization = `Token ${token}`; // Sertakan token dalam header
  }
  return config;
}, (error) => {
  return Promise.reject(error);
});



api.interceptors.response.use(
  (response) => response,
  async (error) => {
    if (error.response && error.response.status === 401) {
      alert("Sesi Anda telah berakhir. Silakan login kembali.");
      localStorage.removeItem("token");
      window.location.href = "/login";
    }
    return Promise.reject(error);
  }
);

export default api;





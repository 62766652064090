<template>
  <b-container fluid class="login-container py-5">
    <b-row class="justify-content-center">
      <b-col cols="24" md="24">
        <b-card class="shadow-lg">
          <b-card-header class="bg-primary text-white text-center">
            <h3>Login</h3>
          </b-card-header>
          <b-card-body>
            
            <b-form @submit.prevent="login">
              <b-form-group label="Email" label-for="email">
                <b-form-input
                  id="email"
                  v-model="email"
                  type="email"
                  required
                  placeholder="Masukkan email Anda"
                ></b-form-input>
              </b-form-group>
              <b-form-group label="Password" label-for="password">
                <b-form-input
                  id="password"
                  v-model="password"
                  type="password"
                  required
                  placeholder="Masukkan password Anda"
                ></b-form-input>
              </b-form-group>
              <b-button variant="primary" block type="submit">
                Login
              </b-button>
              <b-form-floating-label>
              Jika Anda belum memiliki akun, silakan <b-link @click="goToRegister">registrasi</b-link> terlebih dahulu.
              </b-form-floating-label>
            </b-form>
          </b-card-body>
        </b-card>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import api from "@/services/api";
import EventBus from "@/EventBus";

export default {
  data() {
    return {
      email: "",
      password: "",
    };
  },
  methods: {
    async login() {
      try {
        const response = await api.post("/auth/login/", {
          email: this.email,
          password: this.password,
        });

       // Simpan userId, email, dan token ke localStorage
       const { token, userEmail, UserId } = response.data;

        
          localStorage.setItem("token", token);
          localStorage.setItem("userId", UserId);
          localStorage.setItem("userEmail", userEmail);
          EventBus.emit("login"); // Emit event login
          this.$router.push("/dashboard");
         
                
      } catch (error) {
        console.error("Error during login:", error);
        alert("Login gagal. Silakan periksa email dan password Anda.");
      }
    },

    goToRegister() {
      this.$router.push("/register"); // Redirect ke halaman registrasi
    },
  },
};
</script>

<style scoped>
.login-container {
  background: linear-gradient(to bottom right, #f8f9fa, #e9ecef);
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.shadow-lg {
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

b-card-header {
  font-size: 1.5rem;
}
</style>

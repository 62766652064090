// src/EventBus.js
import { reactive } from "vue";

const eventBus = reactive({});

export default {
  on(event, callback) {
    if (!eventBus[event]) {
      eventBus[event] = [];
    }
    eventBus[event].push(callback);
  },
  emit(event, payload) {
    if (eventBus[event]) {
      eventBus[event].forEach((callback) => callback(payload));
    }
  },
  off(event, callback) {
    if (eventBus[event]) {
      eventBus[event] = eventBus[event].filter((cb) => cb !== callback);
    }
  },
};

<template>
  <b-container>
    <b-row class="justify-content-center">
      <b-col cols="12" md="6">
        <b-card>
          <b-card-header>Registrasi</b-card-header>
          <b-card-body>
            <b-form @submit.prevent="register">
              <b-form-group label="Nama" label-for="name">
                <b-form-input
                  id="name"
                  v-model="name"
                  type="text"
                  required
                  placeholder="Masukkan nama lengkap"
                ></b-form-input>
              </b-form-group>
              <b-form-group label="Email" label-for="email">
                <b-form-input
                  id="email"
                  v-model="email"
                  type="email"
                  required
                  placeholder="Masukkan email"
                ></b-form-input>
              </b-form-group>
              <b-form-group label="Password" label-for="password">
                <b-form-input
                  id="password"
                  v-model="password"
                  type="password"
                  required
                  placeholder="Masukkan password"
                ></b-form-input>
              </b-form-group>
              <b-form-group label="Konfirmasi Password" label-for="confirm_password">
                <b-form-input
                  id="confirm_password"
                  v-model="confirmPassword"
                  type="password"
                  required
                  placeholder="Masukkan ulang password"
                ></b-form-input>
              </b-form-group>
              <b-button variant="primary" type="submit">Daftar</b-button>
            </b-form>
          </b-card-body>
        </b-card>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import api from "@/services/api";

export default {
  data() {
    return {
      name: "",
      email: "",
      password: "",
      confirmPassword: "",
    };
  },
  methods: {
    async register() {
      if (this.password !== this.confirmPassword) {
        alert("Password dan Konfirmasi Password tidak cocok.");
        return;
      }
      try {
          await api.post("/auth/register/", {
          name: this.name,
          email: this.email,
          password: this.password,
        });
        alert("Registrasi berhasil! Silakan login.");
        this.$router.push("/login");
      } catch (error) {
        console.error("Error during registration:", error);
        alert("Registrasi gagal. Silakan coba lagi.");
      }
    },
  },
};
</script>
